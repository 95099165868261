// import React from 'react'
// import { BrowserRouter, Route,Routes } from "react-router-dom";
// import Homepage from "./Pages/Homepage";
// import Aboutus from './Pages/Aboutus';
// import Ourip from './Pages/Ourip';
// import Careers from './Pages/Careers';
// import Hardware from './Pages/Hardware';
// import Software from './Pages/Software'
// import Labs from './Pages/Labs';
// import Publications from "./Pages/Publications";
// import News from './Pages/News';
// import Contactus from './Pages/Contactus';

// export default function App() {
//   return <BrowserRouter>
//   <Routes>
//     <Route path="/" Component={Homepage}/>
//     <Route path="/Aboutus" Component={Aboutus}/>
//     <Route path="/Ourip" Component={Ourip}/>
//     <Route path="/Hardware" Component={Hardware}/>
//     <Route path="/Software" Component={Software}/>
//     <Route path="/Labs" Component={Labs}/>
//     <Route path="/Publications" Component={Publications}/>
//     <Route path="/Careers" Component={Careers}/>
//     <Route path="/Contactus" Component={Contactus}/>
 

//   </Routes>
//   </BrowserRouter>
// }

import React, { createContext, useContext, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Homepage from "./Pages/Homepage";
import Aboutus from './Pages/Aboutus';
import Ourip from './Pages/Ourip';
import Careers from './Pages/Careers';
import Hardware from './Pages/Hardware';
import Software from './Pages/Software';
import Labs from './Pages/Labs';
import Publications from "./Pages/Publications";
import Contactus from './Pages/Contactus';
import PageNotFound from './Pages/PageNotFound'; // Create a 404 page component

// Authentication Context
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true); // Replace with real auth logic
  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  // Define protected paths
  const protectedPaths = [
    "/Aboutus",
    "/Ourip",
    "/Hardware",
    "/Software",
    "/Labs",
    "/Publications",
    "/Careers",
    "/Contactus"
  ];

  // Check if the current path is protected and if the user is authenticated
  if (protectedPaths.includes(location.pathname) && !isAuthenticated) {
    return <Navigate to="/404" />;
  }

  return children;
};

// Handle static paths for /static/css, /static/media, and /static/js to redirect to 404
const StaticRedirect = () => {
  const location = useLocation();
  const blockedPaths = ['https://qulabs.ai/static/css', 'https://qulabs.ai/static/media', 'https://qulabs.ai/static/js'];

  // If the path matches any of the blocked paths, redirect to 404
  if (blockedPaths.some(path => location.pathname.startsWith(path))) {
    return <Navigate to="/404" />;
  }

  return null;
};

// App Component
export default function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <StaticRedirect /> {/* Check for blocked static paths */}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route
            path="/Aboutus"
            element={
              <ProtectedRoute>
                <Aboutus />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Ourip"
            element={
              <ProtectedRoute>
                <Ourip />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Hardware"
            element={
              <ProtectedRoute>
                <Hardware />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Software"
            element={
              <ProtectedRoute>
                <Software />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Labs"
            element={
              <ProtectedRoute>
                <Labs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Publications"
            element={
              <ProtectedRoute>
                <Publications />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Careers"
            element={
              <ProtectedRoute>
                <Careers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Contactus"
            element={
              <ProtectedRoute>
                <Contactus />
              </ProtectedRoute>
            }
          />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<PageNotFound />} /> {/* Catch-all for unmatched routes */}
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}
