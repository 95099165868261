import React, { useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { publicationsdata } from "../Data/Publications";
import Publicationcard from "./Publicationcard";

export default function Publicationscontainer() {

  return (
    <div className="relative w-full">
        <div
          className="flex flex-col items-center gap-6 scroll-smooth  p-4"
        >
          {publicationsdata.map((item) => (
                <div key={item.id} className="w-auto h-auto cursor-pointer" >
                  <Publicationcard
                    name={item.name}
                    content={item.content}
                    link={item.link}
                    onReadMoreClick={(data) => console.log('Read more clicked:', data)}
                    
                  />
                </div>
              ))}
        </div>

    </div>
  );
}
