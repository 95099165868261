import React, { useState, useRef, useEffect } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Banner from "../Components/Banner";
import { LuArrowRight } from "react-icons/lu";
import "./../App.css";
import Numbers from "../Components/Numbers";
import Logoscontainer from "../Components/Logoscontainer";
import Servicescontainer from "../Components/Servicescontainer";

export default function Homepage() {
  const [Read, setRead] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div className="w-full bg-[#051525]">
      <Header id={1}/>
      <Banner
        mainBanner={
          <div className="relative w-full  flex items-center justify-center">
            <img
              className="w-full h-[95vh] object-cover mix-blend-exclusion saturate-30 brightness-[0.7]"
              src={require("../../src/Assets/images/Homepage.jpeg")}
            ></img>
            <div className=" transformCenter font-opensans font-extrabold  text-white flex flex-col items-center justify-center   mt-20 text-center">
              <p className="hidden md:block md:text-6xl "
                style={{ WebkitTextStroke: "3px white ", color: "transparent" }}
              >
                We Are Building A{" "}
              </p>
              <p className="block md:hidden   text-[2.5rem]"
                style={{ WebkitTextStroke: "2px white ", color: "transparent" }}
              >
                We Are Building A{" "}
              </p>
              <p  style={{ WebkitTextStroke: "3px #04e3ff ", color: "transparent" }} className=" mt-2 text-center hidden md:block md:text-6xl text-2xl ">Quantum Future</p>
              <p  style={{ WebkitTextStroke: "2px #04e3ff ", color: "transparent" }} className=" mt-[0.3rem] text-center block md:hidden  text-[2.5rem]">Quantum Future</p>
              <p className="md:w-2/3 md:text-xl w-full text-sm font-semibold my-6 text-[#FEFEFE] text-center ">
                We Engineer Solutions To Complex Problems Using Quantum
                Principles
              </p>
             
            </div>
          
          </div>
        }
      />
      <Numbers />
      <p className="w-full text-white font-opensans font-semibold 2xl:text-3xl md:text-2xl mt-2 text-center">
            Services We Can Help You With
          </p>
      <div className="w-full h-[22rem] relative flex items-center justify-center px-5 py-10 gap-8">
            
            <Servicescontainer />
      </div>
      <div className="relative w-full md:h-[33rem] h-[25rem] ">
        <img
          className="w-full xl:h-[33rem] md:h-[30rem] h-[25rem] object-cover"
          src={require("../../src/Assets/images/Ourstory.jpg")}
        ></img>
        <div className="transformright md:right-7 right-3  flex flex-col items-center justify-center  gap-3">
          <div
            style={{ WebkitTextStroke: "3px #DEDEDE ", color: "transparent" }}
            className="hidden md:block font-opensans font-extrabold text-[4.5rem] "
          >
            Our Story
          </div>
          <div
            style={{ WebkitTextStroke: "2px #DEDEDE ", color: "transparent" }}
            className="block md:hidden font-opensans font-extrabold text-[1.5rem] "
          >
            Our Story
          </div>
          <div className="md:w-[40.75rem] w-[20rem]">
            <p className="md:w-[39rem] w-[20rem] font-opensans font-semibold md:text-[1rem] text-[0.5rem] leading-tight text-[#E6FCFF]  text-justify mb-2">
              Started in 2018, we are among the first global companies in
              Quantum Communications & Quantum Artificial Intelligence with our
              prime focus on solutions development in communication security,
              finance, and post-quantum cyber security.
            </p>
            <ul>
              <li className="flex gap-3 text-[#E6FCFF]">
                <img
                  className="md:w-[1.2rem] md:h-[1.2rem] w-[0.7rem] h-[0.7rem]"
                  src={require("../../src/Assets/images/tickicon.png")}
                ></img>
                <p className="font-opensans font-semibold md:text-[0.8rem] text-[0.4rem] text-left">
                  Deliver reliable, high quality, cost-effective, efficient,
                  scalable, and secure quantum communication solutions to a
                  global marketplace
                </p>
              </li>
              <li className="flex gap-3 text-[#E6FCFF]">
                <img
                  className="md:w-[1.2rem] md:h-[1.2rem] w-[0.7rem] h-[0.7rem]"
                  src={require("../../src/Assets/images/tickicon.png")}
                ></img>
                <p className="font-opensans font-semibold md:text-[0.8rem] text-[0.4rem] text-left">
                  Provide the world it's most secure Quantum Internet & create
                  completely secure communication at immense speed and security
                </p>
              </li>
              <li className="flex gap-3 text-[#E6FCFF]">
                <img
                  className="md:w-[1.2rem] md:h-[1.2rem] w-[0.7rem] h-[0.7rem]"
                  src={require("../../src/Assets/images/tickicon.png")}
                ></img>
                <p className="font-opensans font-semibold md:text-[0.8rem] text-[0.4rem] text-left">
                  Develop scalable products and services in quantum computing,
                  controlled by a holistic software stack
                </p>
              </li>
              <li className="flex gap-3 text-[#E6FCFF]">
                <img
                  className="md:w-[1.2rem] md:h-[1.2rem] w-[0.7rem] h-[0.7rem]"
                  src={require("../../src/Assets/images/tickicon.png")}
                ></img>
                <p className="font-opensans font-semibold md:text-[0.8rem] text-[0.4rem] text-left">
                  Prepare next generation skilled manpower and boost
                  translational research in Quantum Hardware and Software
                </p>
              </li>
              <li className="flex gap-3 text-[#E6FCFF]">
                <img
                  className="md:w-[1.2rem] md:h-[1.2rem] w-[0.7rem] h-[0.7rem]"
                  src={require("../../src/Assets/images/tickicon.png")}
                ></img>
                <p className="font-opensans font-semibold md:text-[0.8rem] text-[0.4rem] text-left">
                  Harvest quantum technology for strategic and economic
                  development
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p className="w-full text-[#04E3FF] font-opensans font-extrabold 2xl:text-3xl md:text-2xl mt-16 text-center">Our Latest Projects</p>
      <div className="w-full h-auto relative flex items-center justify-center px-5 py-10 gap-8">
            
            <Logoscontainer />
          
      </div>
      <div className="py-3">
        <p className="font-opensans font-extrabold text-[#04E3FF] text-center leading-loose mb-3">
          Partners
        </p>
        <div className="flex flex-col items-center justify-center md:gap-9 gap-4">
          <div className="flex flex-col items-center justify-center gap-3 py-2">
            <p className="font-opensans font-bold 2xl:text-[1.7rem] md:text-[1.4rem] text-[#FBFBFB]">
              Our Govt. Partners
            </p>
            <div className="flex items-center justify-center gap-8">
              <img
                className="2xl:w-[6rem] 2xl:h-[6rem] 2xl:rounded-[3rem] md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-[1rem] partners-custom"
                src={require("../../src/Assets/images/Govt1.png")}
              ></img>
              <img
                className="2xl:w-[6rem] 2xl:h-[6rem] 2xl:rounded-[3rem] md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-[1rem] partners-custom"
                src={require("../../src/Assets/images/Govt2.png")}
              ></img>
              <img
                className="2xl:w-[6rem] 2xl:h-[6rem] 2xl:rounded-[3rem] md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-[1rem] partners-custom"
                src={require("../../src/Assets/images/Govt3.png")}
              ></img>
              <img
                className="2xl:w-[6rem] 2xl:h-[6rem] 2xl:rounded-[3rem] md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-[1rem] partners-custom"
                src={require("../../src/Assets/images/Govt4.png")}
              ></img>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-3 py-2">
            <p className="font-opensans font-bold 2xl:text-[1.7rem] md:text-[1.4rem] text-[#FBFBFB]">
              Our Academia Partners
            </p>
            <div className="flex items-center justify-center gap-8">
              <img
                className="2xl:w-[6rem] 2xl:h-[6rem] 2xl:rounded-[3rem] md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-[1rem] partners-custom"
                src={require("../../src/Assets/images/Academic1.png")}
              ></img>
              <img
                className="2xl:w-[6rem] 2xl:h-[6rem] 2xl:rounded-[3rem] md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-[1rem] partners-custom"
                src={require("../../src/Assets/images/Academic2.png")}
              ></img>
              <img
                className="2xl:w-[6rem] 2xl:h-[6rem] 2xl:rounded-[3rem] md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-[1rem] partners-custom"
                src={require("../../src/Assets/images/Academic3.png")}
              ></img>
              <img
                className="2xl:w-[6rem] 2xl:h-[6rem] 2xl:rounded-[3rem] md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-[1rem] partners-custom"
                src={require("../../src/Assets/images/Academic4.png")}
              ></img>
              <img
                className="2xl:w-[6rem] 2xl:h-[6rem] 2xl:rounded-[3rem] md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-[1rem] partners-custom"
                src={require("../../src/Assets/images/Academic5.png")}
              ></img>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-3 py-2">
            <p className="font-opensans font-bold 2xl:text-[1.7rem] md:text-[1.4rem] text-[#FBFBFB]">
              Our Business Partners
            </p>
            <div className="flex items-center justify-center gap-8">
              <img
                className="2xl:w-[6rem] 2xl:h-[6rem] 2xl:rounded-[3rem] md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-[1rem] partners-custom"
                src={require("../../src/Assets/images/Business1.png")}
              ></img>
              <img
                className="2xl:w-[6rem] 2xl:h-[6rem] 2xl:rounded-[3rem] md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-[1rem] partners-custom"
                src={require("../../src/Assets/images/Business2.png")}
              ></img>
              <img
                className="2xl:w-[6rem] 2xl:h-[6rem] 2xl:rounded-[3rem] md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-[1rem] partners-custom"
                src={require("../../src/Assets/images/Business3.png")}
              ></img>
              <img
                className="2xl:w-[6rem] 2xl:h-[6rem] 2xl:rounded-[3rem] md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-[1rem] partners-custom"
                src={require("../../src/Assets/images/Business4.png")}
              ></img>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-3 py-2">
            <p className="font-opensans font-bold 2xl:text-[1.7rem] md:text-[1.4rem] text-[#FBFBFB]">
              Our Industry Partners
            </p>
            <div className="flex items-center justify-center gap-8">
              <img
                className="2xl:w-[6rem] 2xl:h-[6rem] 2xl:rounded-[3rem] md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-[1rem] partners-custom"
                src={require("../../src/Assets/images/Industry1.png")}
              ></img>
              <img
                className="2xl:w-[6rem] 2xl:h-[6rem] 2xl:rounded-[3rem] md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-[1rem] partners-custom"
                src={require("../../src/Assets/images/Industry2png.png")}
              ></img>
              <img
                className="2xl:w-[6rem] 2xl:h-[6rem] 2xl:rounded-[3rem] md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-[1rem] partners-custom"
                src={require("../../src/Assets/images/Industry3.png")}
              ></img>
            </div>
          </div>
        </div>
      </div>

      <Footer showpage={true}/>
    </div>
  );
}
