import React, { useState } from "react";

export default function Labcard(props) {
  const [isHovered, setHovered] = useState(false);
  return (
    <div
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={`2xl:w-[13rem] 2xl:h-[13rem] md:w-[10rem] md:h-[10rem] w-[7rem] h-[7rem] flex flex-col  items-center justify-between 2xl:gap-6 md:gap-3 px-2 py-3 ${props.id===3?"mt-6":""}`}
    >
      <img src={props.img} alt={props.name} className="2xl:w-[9rem] 2xl:h-[9rem] md:w-[8rem] md:h-[8rem] w-[5rem] h-[5rem] object-cover" />
      <p className="text-white font-opensans font-bold 2xl:text-[0.8rem] md:text-[0.7rem] text-[0.5rem] text-center w-[10rem]">
        {props.name}
      </p>
    </div>
  );
}
