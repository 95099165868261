import React from "react";
import { useState } from "react";

export default function Services(props) {
  const [isHovered, setIsHovered] = useState(false);
  const gradientStyle = {
    background: isHovered
      ? "radial-gradient(circle, rgb(230, 252, 255,0.4), #0C2E52)"
      : "#0C2E52",
    transition: "background 0.3s ease-in-out",
  };

  return (
    <div
      style={gradientStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="md:px-8 md:py-6 px-4 py-5 flex flex-col gap-2 md:w-[23rem] md:h-[14rem] w-[14rem] h-[12rem] md:rounded-2xl rounded-xl hover:h-[12.5rem] md:hover:h-[15.3rem] hover:border-[#E6FCFF]/20 hover:border-2 md:hover:border"
    >
      <div className="flex md:gap-3 gap-2 md:py-1 pt-1 items-center justify-center">
        {props.logo && (
          <img
            className="md:w-[3.75rem] md:h-[3.75rem] w-[2.5rem] h-[2.5rem]"
            src={require(`../../src/Assets/images/${props.logo}.png`)}
            alt={`${props.heading} Logo`}
          />
        )}
        <div className="flex flex-wrap md:w-[14.1rem] w-[9rem] text-white font-opensans font-semibold md:text-xl text-sm ">
          {props.heading}
        </div>
      </div>
      <div className="  border border-[#D9FBFF]/50 "></div>
      <p className="text-white font-opensans font-semibold md:text-[0.625rem] text-[0.55rem] flex flex-wrap text-justify md:mt-2 px-1 py-1 leading-tight">
        {props.data}
      </p>
    </div>
  );
}
