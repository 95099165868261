import React, { useState } from "react";
import { FaChevronDown, FaChevronUp, FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function Header(props) {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [Products, setProducts] = useState(false);
  const [Hardware, setHardware] = useState(false);
  const [Software, setSoftware] = useState(false);
  const [Researchhover, setResearchhover] = useState(false);
  const [Labshover, setLabshover] = useState(false);
  const [Publicationshover, setPublicationshover] = useState(false);
  const [researchClicked, setResearchClicked] = useState(false);
  const active=props.id;
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div className="w-full md:h-[14%] h-[10%] bg-[#051525] flex items-center justify-center  fixed top-0 z-10 shadow-xl">
      <div className="w-[90%] flex items-center justify-between">
        <div>
        
        <img className="w-[5rem] h-[4rem]  md:w-[7rem] md:h-[5rem] 2xl:w-[8rem] 2xl:h-[6rem]" src={require("../../src/Assets/images/logo.png")} alt="Qulabs Logo" />
        </div>
        <div className="sm:block hidden">
          <ul className="flex items-center justify-center text-white 2xl:gap-5 md:gap-3">
            <li
              onClick={() => {
                scrollToTop();
                navigate("/")
              }}
              className={`px-3 py-1 bg-[#051525]  hover:text-[#216DC0] cursor-pointer text-[#B1F6FF] ${
                active === 1
                  ? "border-b-2 hover:text-[#B1F6FF]  border-[#B1F6FF]"
                  : "border-b-0"
              } md:text-[0.7rem] 2xl:text-base`}
            >
              Home
            </li>
            <li
              onClick={() => {
                scrollToTop();
                navigate("/Aboutus")
                
              }}
              className={`px-3 py-1 bg-[#051525]  hover:text-[#216DC0] cursor-pointer text-[#B1F6FF] ${
                active === 2
                  ? "border-b-2 hover:text-[#B1F6FF]  border-[#B1F6FF]"
                  : "border-b-0"
              } md:text-[0.7rem] 2xl:text-base`}
            >
              About Us
            </li>
            <li
              onMouseLeave={() => {
                setProducts(false);
              }}
              onMouseOver={() => {
                setProducts(true);
              }}
              className={`relative flex items-center justify-center gap-2 p-3 bg-[#051525] hover:text-[#216DC0] cursor-pointer text-[#B1F6FF] ${
                active === 3
                  ? "border-b-2 hover:text-[#B1F6FF]   border-[#B1F6FF]"
                  : "border-b-0"
              } md:text-[0.7rem] 2xl:text-base`}
            >
              Products
              <FaChevronDown className="mt-[0.10rem]" />
              <div
                onMouseLeave={() => {
                  setProducts(false);
                }}
                className={`flex flex-col items-center justify-center absolute top-[2.5rem]  left-0 w-[10rem] py-3 bg-[#051525] ${
                  Products ? "block" : "hidden"
                } md:text-[0.7rem] 2xl:text-base`}
              >
                <p
                  onClick={() => {
                    scrollToTop();
                    navigate("/Hardware")
                  }}
                  onMouseOver={() => {
                    setHardware(true);
                  }}
                  onMouseLeave={() => {
                    setHardware(false);
                  }}
                  className="flex items-center justify-center gap-3 p-3 w-[8rem] text-[#B1F6FF] hover:text-[#216DC0] hover:bg-[#0B294A]/30 hover:rounded-xl md:text-[0.7rem] 2xl:text-base"
                >
                  Hardware{" "}
                  
                </p>
                <p
                  onClick={() => {
                    scrollToTop();
                    navigate("/Software")
                  }}
                  onMouseOver={() => {
                    setSoftware(true);
                  }}
                  onMouseLeave={() => {
                    setSoftware(false);
                  }}
                  className="flex items-center justify-center gap-3 p-3 w-[8rem] text-[#B1F6FF] hover:text-[#216DC0] hover:bg-[#0B294A]/30 hover:rounded-xl md:text-[0.7rem] 2xl:text-base"
                >
                  Software{" "}
                  
                </p>
              </div>
            </li>

            <li
              onClick={() => {
                scrollToTop();
                navigate("/Ourip")
              }}
              className={`px-3 py-1 bg-[#051525]  hover:text-[#216DC0] cursor-pointer text-[#B1F6FF] ${
                active === 4
                  ? "border-b-2 hover:text-[#B1F6FF]  border-[#B1F6FF]"
                  : "border-b-0"
              } md:text-[0.7rem] 2xl:text-base`}
            >
              Our IPs
            </li>
            <li
              onMouseLeave={() => {
                setResearchhover(false);
              }}
              onMouseOver={() => {
                setResearchhover(true);
              }}
            
              className={`relative flex items-center justify-center gap-2 p-3 bg-[#051525] hover:text-[#216DC0] cursor-pointer text-[#B1F6FF] ${
                active === 5
                  ? "border-b-2 hover:text-[#B1F6FF]   border-[#B1F6FF]"
                  : "border-b-0"
              } md:text-[0.7rem] 2xl:text-base`}
            >
              Research
              <FaChevronDown className="mt-[0.10rem]" />
              <div
                onMouseLeave={() => {
                  setResearchhover(false);
                }}
                className={`flex flex-col items-center justify-center absolute top-[2.5rem]  left-0 w-[10rem] py-3 bg-[#051525] ${
                  Researchhover ? "block" : "hidden"
                } md:text-[0.7rem] 2xl:text-base`}
              >
                <p
                  onClick={() => {
                    scrollToTop();
                    navigate("/Labs")
                  }}
                  onMouseOver={() => {
                    setLabshover(true);
                  }}
                  onMouseLeave={() => {
                    setLabshover(false);
                  }}
                  className="flex items-center justify-center gap-3 p-3 w-[8rem] text-[#B1F6FF] hover:text-[#216DC0] hover:bg-[#0B294A]/30 hover:rounded-xl md:text-[0.7rem] 2xl:text-base"
                >
                  Labs{" "}
                  
                </p>
                <p
                  onClick={() => {
                    scrollToTop();
                    navigate("/Publications")
                  }}
                  onMouseOver={() => {
                    setPublicationshover(true);
                  }}
                  onMouseLeave={() => {
                    setPublicationshover(false);
                  }}
                  className="flex items-center justify-center gap-3 p-3 w-[8rem] text-[#B1F6FF] hover:text-[#216DC0] hover:bg-[#0B294A]/30 hover:rounded-xl md:text-[0.7rem] 2xl:text-base"
                >
                  Publications{" "}
                  
                </p>
              </div>
            </li>
            <li
              onClick={() => {
                scrollToTop();
                navigate("/Contactus")
              }}
              className={`px-3 py-1 bg-[#051525]  hover:text-[#216DC0] cursor-pointer text-[#B1F6FF] ${
                active === 6
                  ? "border-b-2 hover:text-[#B1F6FF]  border-[#B1F6FF]"
                  : "border-b-0"
              } md:text-[0.7rem] 2xl:text-base`}
            >
              Contact Us
            </li>
            <li
              onClick={() => {
                scrollToTop();
                navigate("/Careers")
              }}
              className={`px-3 py-1 bg-[#051525]  hover:text-[#216DC0] cursor-pointer text-[#B1F6FF] ${
                active === 7
                  ? "border-b-2 hover:text-[#B1F6FF]  border-[#B1F6FF]"
                  : "border-b-0"
              } md:text-[0.7rem] 2xl:text-base`}
            >
              {" "}
              Careers
            </li>
            
            {/* <li
              onClick={() => {
                scrollToTop();
                navigate("/News")
              }}
              className={`px-3 py-1 bg-[#051525]  hover:text-[#216DC0] cursor-pointer text-[#B1F6FF] ${
                active === 9
                  ? "border-b-2 hover:text-[#B1F6FF]  border-[#B1F6FF]"
                  : "border-b-0"
              } md:text-[0.7rem] 2xl:text-base`}
            >
              News
            </li> */}
            {/* <li
               onClick={() => {
                window.open("https://www.quacademy.com/", "_blank");
              }}
              className={`px-3 py-1 bg-[#051525]  hover:text-[#216DC0] cursor-pointer border-2 border-[#b1f6ff66] bg-[#b1f6ff1e] rounded-lg text-[#B1F6FF] ${
                active === 8
                  ? "border-b-2 hover:text-[#B1F6FF]  border-[#B1F6FF]"
                  : ""
              } md:text-[0.7rem] 2xl:text-base`}
            >
              QuAcademy
            </li>
            <li
               onClick={() => {
                window.open("https://thequantum.ai/", "_blank");
              }}
              className={`px-3 py-1 bg-[#051525]  hover:text-[#216DC0] cursor-pointer border-2 border-[#b1f6ff66] bg-[#b1f6ff1e] rounded-lg text-[#B1F6FF] ${
                active === 10
                  ? "border-b-2 hover:text-[#B1F6FF]  border-[#B1F6FF]"
                  : ""
              } md:text-[0.7rem] 2xl:text-base`}
            >
              Quantum AI
            </li> */}
          </ul>
        </div>
        <div className="sm:hidden">
          <FaBars className="text-[#B1F6FF] text-2xl cursor-pointer"  onClick={() => setShowMenu(!showMenu)} />
        </div>

      </div>
       {showMenu && (
        <div className={`absolute  top-full right-0 w-[50%] h-${researchClicked ? '45' : '40'}% bg-[#051525] text-white py-3`}>
           <ul className="flex  flex-col items-center justify-center text-white gap-3 ">
            <li
              onClick={() => {
                scrollToTop();
                navigate("/")
              }}
              className={`px-3 py-1 bg-[#051525]  hover:text-[#216DC0] cursor-pointer text-[#B1F6FF] ${
                active === 1
                  ? "border-b-2 hover:text-[#B1F6FF]  border-[#B1F6FF]"
                  : "border-b-0"
              } text-[0.7rem] `}
            >
              Home
            </li>
            <li
              onClick={() => {
                scrollToTop();
                navigate("/Aboutus")
                
              }}
              className={`px-3 py-1 bg-[#051525]  hover:text-[#216DC0] cursor-pointer text-[#B1F6FF] ${
                active === 2
                  ? "border-b-2 hover:text-[#B1F6FF]  border-[#B1F6FF]"
                  : "border-b-0"
              } text-[0.7rem] `}
            >
              About Us
            </li>
            <li
              onClick={() => {
                setProducts(!Products);
              }}
              className={`relative flex items-center justify-center gap-2 p-3 bg-[#051525] hover:text-[#216DC0] cursor-pointer text-[#B1F6FF] ${
                active === 3
                  ? "border-b-2 hover:text-[#B1F6FF]   border-[#B1F6FF]"
                  : "border-b-0"
              } text-[0.7rem] `}
            >
              Products
              {Products ? (
          <FaChevronUp className="mt-[0.10rem]" />
        ) : (
          <FaChevronDown className="mt-[0.10rem]"  />
        )}
         
            </li>
            <li
                onMouseLeave={() => {
                  setProducts(false);
                }}
                className={`flex flex-col items-center justify-center  w-[10rem]  bg-[#b1f6ff1e] rounded-lg ${
                  Products ? "block" : "hidden"
                } text-[0.7rem] `}
              >
                <p
                  onClick={() => {
                    scrollToTop();
                    navigate("/Hardware")
                  }}
                  onMouseOver={() => {
                    setHardware(true);
                  }}
                  onMouseLeave={() => {
                    setHardware(false);
                  }}
                  className="cursor-pointer flex items-center justify-center gap-3 px-3 py-3 w-[8rem] text-[#B1F6FF] hover:text-[#216DC0] hover:bg-[#0B294A]/30 hover:rounded-xl text-[0.7rem] "
                >
                  Hardware{" "}
                  
                </p>
                <p
                  onClick={() => {
                    scrollToTop();
                    navigate("/Software")
                  }}
                  onMouseOver={() => {
                    setSoftware(true);
                  }}
                  onMouseLeave={() => {
                    setSoftware(false);
                  }}
                  className=" cursor-pointer flex items-center justify-center gap-3  px-3 py-3 w-[8rem] text-[#B1F6FF] hover:text-[#216DC0] text-[0.7rem] "
                >
                  Software{" "}
                  
                </p>
              </li>

            <li
              onClick={() => {
                scrollToTop();
                navigate("/Ourip")
              }}
              className={`px-3 py-1 bg-[#051525]  hover:text-[#216DC0] cursor-pointer text-[#B1F6FF] ${
                active === 4
                  ? "border-b-2 hover:text-[#B1F6FF]  border-[#B1F6FF]"
                  : "border-b-0"
              } text-[0.7rem] `}
            >
              Our IPs
            </li>
            <li
             
              onClick={() => {
                setResearchhover(!Researchhover);
              }}
            
              className={` flex items-center justify-center gap-2 p-3 bg-[#051525] hover:text-[#216DC0] cursor-pointer text-[#B1F6FF] ${
                active === 5
                  ? "border-b-2 hover:text-[#B1F6FF]   border-[#B1F6FF]"
                  : "border-b-0"
              } text-[0.7rem] ${Researchhover ? 'h-auto' : ''}`}
            >
              Research
              {Researchhover ? (
          <FaChevronUp className="mt-[0.10rem]" />
        ) : (
          <FaChevronDown className="mt-[0.10rem]"  />
        )}
              
            </li>
            <li
                onMouseLeave={() => {
                  setResearchhover(false);
                }}
                className={`flex flex-col items-center justify-center  w-[10rem] bg-[#b1f6ff1e] rounded-lg ${
                  Researchhover ? "block" : "hidden"
                } text-[0.7rem] `}
              >
                <p
                  onClick={() => {
                    scrollToTop();
                    navigate("/Labs")
                  }}
                  onMouseOver={() => {
                    setLabshover(true);
                  }}
                  onMouseLeave={() => {
                    setLabshover(false);
                  }}
                  className="cursor-pointer flex items-center justify-center gap-3 p-3 w-[8rem] text-[#B1F6FF] hover:text-[#216DC0] hover:bg-[#0B294A]/30 hover:rounded-xl text-[0.7rem]"
                >
                  Labs{" "}
                  
                </p>
                <p
                  onClick={() => {
                    scrollToTop();
                    navigate("/Publications")
                  }}
                  onMouseOver={() => {
                    setPublicationshover(true);
                  }}
                  onMouseLeave={() => {
                    setPublicationshover(false);
                  }}
                  className="cursor-pointer flex items-center justify-center gap-3  p-3 w-[8rem] text-[#B1F6FF] hover:text-[#216DC0] text-[0.7rem]"
                >
                  Publications{" "}
                  
                </p>
              </li>
            <li
              onClick={() => {
                scrollToTop();
                navigate("/Contactus")
              }}
              className={`px-3 py-1 bg-[#051525]  hover:text-[#216DC0] cursor-pointer text-[#B1F6FF] ${
                active === 6
                  ? "border-b-2 hover:text-[#B1F6FF]  border-[#B1F6FF]"
                  : "border-b-0"
              } text-[0.7rem] `}
            >
              Contact Us
            </li>
            <li
              onClick={() => {
                scrollToTop();
                navigate("/Careers")
              }}
              className={`px-3 py-1 bg-[#051525]  hover:text-[#216DC0] cursor-pointer text-[#B1F6FF] ${
                active === 7
                  ? "border-b-2 hover:text-[#B1F6FF]  border-[#B1F6FF]"
                  : "border-b-0"
              } text-[0.7rem] `}
            >
              {" "}
              Careers
            </li>
            
            {/* <li
              onClick={() => {
                scrollToTop();
                navigate("/News")
              }}
              className={`px-3 py-1 bg-[#051525]  hover:text-[#216DC0] cursor-pointer text-[#B1F6FF] ${
                active === 9
                  ? "border-b-2 hover:text-[#B1F6FF]  border-[#B1F6FF]"
                  : "border-b-0"
              } text-[0.7rem] `}
            >
              News
            </li> */}
            {/* <li
               onClick={() => {
                window.open("https://www.quacademy.com/", "_blank");
              }}
              className={`px-3 py-1   hover:text-[#216DC0] cursor-pointer border-2 border-[#b1f6ff66] bg-[#b1f6ff1e] rounded-lg text-[#B1F6FF] ${
                active === 8
                  ? "border-b-2 hover:text-[#B1F6FF]  border-[#B1F6FF]"
                  : ""
              } text-[0.7rem]`}
            >
              QuAcademy
            </li>
            <li
               onClick={() => {
                window.open("https://thequantum.ai/", "_blank");
              }}
              className={`px-3 py-1   hover:text-[#216DC0] cursor-pointer border-2 border-[#b1f6ff66] bg-[#b1f6ff1e] rounded-lg text-[#B1F6FF] ${
                active === 10
                  ? "border-b-2 hover:text-[#B1F6FF]  border-[#B1F6FF]"
                  : ""
              } text-[0.7rem]`}
            >
              Quantum AI
            </li> */}
          </ul>
         
        </div>
      )}
    </div>
  );
}
