import React from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Banner from "../Components/Banner";
import Publicationscontainer from '../Components/Publicationscontainer';
import "./../App.css";

export default function Publications() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div className="w-full bg-[#051525]">
    <Header id={5} />
    <Banner
      mainBanner={
        <div className="relative h-[95vh] flex items-center justify-center">
          <img
            className="w-full h-[95vh] object-cover  brightness-90  saturate-50"
            src={require("../../src/Assets/images/Publications.jpeg")}
          ></img>
          <div className="flex flex-col items-start justify-center transformleft sm:left-14 left-4  gap-4">
          <div
              className=" font-opensans font-bold text-6xl text-left hidden md:block "
              style={{ WebkitTextStroke: "2px white ", color: "transparent" }}
            >
              Publications
            </div>
            <div
                className=" font-opensans font-bold text-3xl text-left block md:hidden"
                style={{ WebkitTextStroke: "2px white ", color: "transparent" }}
              >
                Publications
              </div>
            <p className="font-opensans font-medium md:text-sm text-[0.6rem] text-white sm:w-[25.68rem] w-[20rem] text-left">
            </p>
          </div>
        </div>
      }
    />
    <p className="w-full text-center font-opensans font-extrabold 2xl:text-3xl md:text-2xl text-[#E7EAEE] mt-10">
          Publications
        </p>
      <div className="w-full h-auto relative flex items-center justify-center px-5 mb-10 gap-8 ">
           <Publicationscontainer/>  
      </div>
    <Footer showpage={false}/>
    </div>
  )
}
