export const labdata=[
    {
        id:1,
        name:"IIT Hyderabad",
        img:require("../../src/Assets/images/Hyderabad.png"),
        location:"",
    },
    {
        id:2,
        name:"IIT Roorkee",
        img:require("../../src/Assets/images/Roorkee.png"),
        location:"",
    },
    {
        id:3,
        name:"Quantum Optic lab Hyderabad, India",
        img:require("../../src/Assets/images/logo.png"),
        location:"",
    },
    {
        id:4,
        name:"IIT Kharagpur",
        img:require("../../src/Assets/images/Kharagpur.png"),
        location:"",
    },
];