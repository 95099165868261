export const hardware = [
  {
    head: "Quantum Memory",
    content:
      "Room Temperature Quantum Memory represents a breakthrough in quantum technology, enabling storage and retrieval of quantum information at ambient conditions. Unlike traditional quantum memories requiring ultra-cold temperatures, this innovation allows for practical integration into existing systems, facilitating advancements in quantum communication, computing and cryptography.",
  },
  {
    head: " Single Photon Source",
    content:
      "Single Photon Sources are fundamental component in quantum technology, emitting individual light particles with precision and reliability. These sources are crucial for quantum communication, cryptography, and computation, providing the building blocks for secure data transmission and quantum information processing. ",
  },
  {
    head: "Entangled Photon Source ",
    content:
      "Entangled Photon Sources work by producing pairs of photons that are quantumly entangled, a state where the properties of one photon are intrinsically connected to the other, regardless of distance. This phenomenon is harnessed through specialized quantum processes, often involving nonlinear optical materials. In these sources, when a photon is split, it creates two entangled photons. ",
  },
  {
    head: "Entanglement QKD demonstrator toolkit",
    content:
      "The 'Entanglement-based QKD Demonstrator Toolkit' is a specialized setup designed for illustrating and experimenting with Quantum Key Distribution (QKD) using entangled photons. This toolkit encompasses the necessary components and mechanisms to generate, manipulate, and measure entangled photon pairs, thereby demonstrating the fundamental principles of QKD. It serves as an educational and research tool, enabling users to understand and showcase how entanglement can be used for ultra-secure communication. ",
  },
  {
    head: "Quantum magnetometry for MRI ",
    content:
      "Quantum Magnetometer is a sophisticated instrument that leverages quantum mechanics to measure magnetic fields with exceptional precision. This technology is particularly transformative in Magnetic Resonance Imaging (MRI). By detecting minute variations in magnetic fields, this magnetometer enhances the clarity and detail of MRI scans. Its quantum-based sensitivity allows for earlier and more accurate detection of medical conditions, offering significant advancements in diagnostic imaging.",
  },
  {
    head: "Single Photon Detector",
    content:
      "Single Photon Detectors are essential instruments in quantum technology, designed to detect individual photons with exceptional accuracy and sensitivity. These detectors are vital for quantum communication, cryptography, and computing, acting as critical components for analyzing and processing quantum information. By identifying single photons, Single Photon Detectors enable precise measurement and minimal noise interference, essential for sophisticated quantum experiments and applications. ",
  },
  {
    head: "Laser encoder box",
    content:
      "Introducing our Laser-Encoder Pulse Module, a groundbreaking solution for precise pulse sequence generation. This module efficiently processes output from two phase-locked and frequency-locked lasers, creating tailored pulse sequences to meet user specifications. Additionally, it attenuates the output to single-photon levels while providing arbitrary polarization qubit encoding. Revolutionize quantum optics experiments with this advanced technology, offering unparalleled control and customization for laser applications in research and development.",
  },
  {
    head: "Laser control unit",
    content: "Introducing our state-of-the-art Laser-Controlled Module, a versatile device compatible with any laser-cavity piezo system. This module seamlessly integrates with diverse laser setups, offering precise control and optimization. Its adaptability ensures compatibility across various configurations, allowing researchers and technicians to enhance laser system performance effortlessly. Experience unparalleled convenience and flexibility in laser-cavity operations with our innovative Laser-Controlled Module, setting a new standard in laser technology adaptability. ",
  },
  {
    head: "Miniature Rubidium Vapor Cell",
    content:
      "Introducing our cutting-edge Miniaturized Rb Vapor Cell, designed to cater to both Rb-85 and Rb-87 isotopes in natural and enriched forms. This compact device ensures precision in atomic studies and quantum applications. With enhanced portability and versatility, researchers can explore a wide range of experiments with ease. The Miniaturized Rb Vapor Cell promises exceptional performance, making it an indispensable tool for scientific endeavours requiring accuracy and flexibility in manipulating rubidium isotopes.",
  },
  {
    head: "Quantum Repeater",
    content:
      "Quantum repeaters employ a process known as entanglement swapping. This involves creating entangled pairs of photons, sending them to intermediary nodes, and performing entanglement swapping operations to generate new entangled pairs. Through this iterative process, the entanglement is effectively 'repeated', allowing for the establishment of entanglement connections between distant nodes without direct photon transmission over the entire distance. ",
  },
  {
    head: "Quantum Gravimeter",
    content:
      "Quantum Gravimeters are pivotal devices in precision measurement technology, utilizing quantum mechanics to measure gravitational acceleration with extraordinary accuracy. These instruments are crucial in geophysics, navigation, and metrology, providing key insights into Earth's gravitational field and subsurface features. By manipulating and observing the behavior of atoms in a quantum state, Quantum Gravimeters detect minute changes in gravitational pull, enabling highly sensitive and detailed geological surveys.",
  },
  {
    head:"Rydberg sensor",
    content:"Rydberg sensors leverage the extreme sensitivity of Rydberg atoms to electric and magnetic fields to detect and measure these fields with high precision. By using laser beams to excite atoms to Rydberg states, these sensors can perform measurements without physically contacting the field, making them useful in a variety of applications ranging from fundamental physics research to practical electromagnetic field mapping and quantum computing technologies. Their unparalleled sensitivity allows for the detection of very weak signals."
  },
  {
    head: "Quantum Memory",
    content:
      "Room Temperature QM Demonstrator Box for Academia Witness the potential of Quantum Memory with our Room Temp QM Demonstrator Box, complete with an intuitive input preparation module for seamless experimentation.",
  },
];

export const software = [
  {
    head: "Q-Folio Pro",
    content:
      "The rapid advancement of quantum computing technology has opened new possibilities in the field of portfolio optimization. We are creating RoboQop, an innovative software-based application that combines quantum computing capabilities with traditional roboadvisor functionality to offer enhanced portfolio management solutions.",
  },
  {
    head: "QNTSim",
    content:
      "A robust simulation tool to benchmark your quantum network architecture against different workloads. Simulate real-world quantum communication applications using our intuitive web interface. Designed for corporates to evaluate the power of quantum secure communications, and for academics to advance breakthroughs in quantum networking.",
  },
  {
    head: "QuRNG",
    content:
      "Quantum random number generator - a tool that uses quantum mechanics to produce high-quality random bits. This technology offers greater security and randomness compared to traditional methods that rely on predictable algorithms or physical processes. The software is accessible and affordable for all sizes of businesses and organizations and can be used remotely. It is ideal for applications in cyber security, data encryption, and more.",
  },
  {
    head: "BQSim",
    content:
      "The Blind Quantum Computing (BQC) simulator represents a cutting-edge platform designed to facilitate secure, quantum computing processes, where the computation details remain hidden from the quantum computer itself. This innovative approach enables users to execute quantum algorithms without revealing their data or the algorithm's nature, preserving privacy and security. The BQC simulator serves as an invaluable tool for researchers and developers, offering a sandbox environment to explore quantum computing's potential applications and challenges. ",
  },
  {
    head: "QuBlinQS",
    content:
      "Blind Quantum Signature (BQS) technology revolutionizes financial transaction security by integrating quantum mechanics with cryptography, ensuring transaction authenticity while preserving anonymity. This approach prevents fraud by enabling secure, unforgeable verification processes without revealing transaction details. BQS is pivotal for enhancing privacy in online banking and secure voting, despite being in early development stages.",
  },
  {
    head: "QloudB",
    content:
      "The Blind Quantum Computing project at Qulabs aspires to achieve and provide secure delegated cloud quantum computing for any classical client through blind quantum computing technology. Qloudb is one of the tools we built to provide the facility to delegate universal quantum computation privately to servers (server itself doesn’t have the knowledge of intended computation) such as IBMQ, AWS-Braket and Azure Quantum platforms.",
  },
  {
    head: "Chec-Q",
    content:
      " Quantum Safe algorithm (PQC) scan through an organization’s security system and discover vulnerable cryptographic practices. Quantum Computing takes advantage of Quantum phenomena Superposition principle, entanglement interference etc. to perform computation. Quantum Computing provides exponential speed up in solving problems like discrete logarithm, factorization etc. that were difficult for classical computers. The current state of quantum computers is not advanced enough.",
  },
];
