import React from 'react'
import { useState } from 'react';

export default function Numbercomponent(props) {
    const [isHovered, setIsHovered] = useState(false);
    const gradientStyle = {
      background: isHovered
        ? 'radial-gradient(circle, rgb(230, 252, 255,0.2), #051525)'
        : 'initial',
      transition: 'background 0.3s ease-in-out',}
  return (
    <div style={gradientStyle}   onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}  className="2xl:w-[17.5rem] md:w-[15rem] md:h-[7.4rem]  w-[10rem] h-[4.7rem] flex flex-col justify-center border-2 border-[#B1F6FF] rounded-2xl px-6 py-4 hover:bg-[#E6FCFF]/10 hover:border-[#B1F6FF]/30 cursor-pointer "
    >
            <p  className="text-white font-opensans font-semibold 2xl:text-4xl md:text-3xl text-xl mb-2 ">
              {props.number}
            </p>
            <p className="text-white font-opensans font-semibold 2xl:text-xl md:text-lg text-[0.55rem] mb-1">
              {props.name}
            </p>
          </div>
    

  )
}
