import React, { useState } from "react";
import { SlSocialLinkedin } from "react-icons/sl";

export default function Advisors(props) {
  const [isHovered, setIsHovered] = useState(false);
  const openLink = (url, event) => {
    // Stop event propagation to prevent the div click event from triggering
    event.stopPropagation();
    window.open(url, "_blank");
  };

  return (
    <div
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="flex flex-col items-center justify-center 2xl:gap-6 md:gap-4 gap-1 2xl:px-6 2xl:py-8 md:px-4 md:py-6 bg-white/10 md:rounded-2xl hover:bg-[#D9FBFF]/20 2xl:w-[24rem] 2xl:h-[8rem] xl:w-[19rem] xl:h-[6rem] lg:w-[15rem] lg:h-[4rem] w-[10rem] h-[3.5rem] rounded-md"
    >
      <div className="flex flex-col items-center justify-center md:gap-6 gap-1">
        <div className="w-full flex items-center justify-between md:gap-0 gap-3">
          <div className="2xl:w-[17rem] xl:w-[14rem] lg:w-[10rem] w-[5rem] flex flex-col items-start justify-center md:gap-1 gap-[0.15rem]">
            <p className="font-opensans font-extrabold 2xl:text-lg xl:text-[0.7rem] lg:text-[0.6rem] text-[#E7EAEE] text-[0.4rem] text-left">
              {props.name}
            </p>
            <p
              className={`font-opensans font-bold 2xl:text-[0.675rem] xl:text-[0.6rem] md:text-[0.5rem] text-[0.3rem]  text-left ${
                isHovered ? "text-blue-50" : ""
              } text-[#E7EAEE]/50`}
            >
              {props.title}
            </p>
          </div>
          <div
            onClick={(event) => openLink(props.linkedin, event)}
            className="2xl:w-[3rem] 2xl:h-[3rem] 2xl:rounded-[0.75rem] md:w-[2.5rem] md:h-[2.5rem] md:rounded-[0.6rem] w-[2rem] h-[2rem] rounded-[0.75rem] bg-[#03AABF]/50 flex items-center justify-center hover:bg-[#0C2E52]/60"
          >
            <SlSocialLinkedin size={20} color="white" className="hidden md:block" />
            <SlSocialLinkedin size={12} color="white" className="block md:hidden" />
          </div>
        </div>
      </div>
    </div>
  );
}
