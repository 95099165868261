import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Banner from "../Components/Banner";
import "./../App.css";
import { ipdata } from "../Data/Ipdata";
import Ipcard from "../Components/Ipcard";


export default function Ourip() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div className="w-full bg-[#051525]">
      <Header id={4} />
      <Banner
        mainBanner={
          <div className="relative h-[95vh] flex items-center justify-center">
            <img
              className="w-full h-[95vh] object-cover  mix-blend-difference"
              src={require("../../src/Assets/images/Ips.jpeg")}
            ></img>
            <div className="flex flex-col items-start justify-center transformleft sm:left-14 left-4  gap-4">
              <div
                className=" font-opensans font-bold text-6xl text-left hidden md:block"
                style={{ WebkitTextStroke: "2px white ", color: "transparent" }}
              >
                Create & Own
              </div>
              <div
                className=" font-opensans font-bold text-3xl text-left block md:hidden"
                style={{ WebkitTextStroke: "2px white ", color: "transparent" }}
              >
                Create & Own
              </div>
              <p className="font-opensans font-medium md:text-sm text-[0.6rem] text-white sm:w-[25.68rem] w-[20rem] text-left">
                At Qulabs, we are constantly in the process of rediscovering
                possibilities and reimagining boundaries using the power of
                quantum communications and the innovations that we deliver as a
                result of the same. The IPs that we own are intended to protect
                leading edge technologies that we produce through our R&D
                efforts and designs.
              </p>
            </div>
          </div>
        }
      />
      <div className="flex flex-col items-center justify-center gap-6  px-19 py-12">
        <p className="font-opensans font-semibold 2xl:text-3xl md:text-2xl text-white">
        Our IP Protection Initiatives
        </p>
        <p className="md:w-[49.75rem] w-[80%] text-justify font-opensans font-semibold 2xl:text-sm md:text-[0.7rem] text-[0.4rem] text-[#E6FCFF] py-6">
        For many years, our efforts to protect our intellectual property have been a high priority. These cumulative efforts have served as the driving force that has underpinned our ability to create value for all stakeholders involved with us. By enhancing the level of cooperation between our intellectual property divisions, our academies and our operations, we are formulating an intellectual property strategy designed to generate and create new business.
        </p>
      </div>
      <div className="flex flex-wrap max-w-full gap-5 mx-auto items-center justify-center md:px-40 md:py-10 px-3 py-7">
      {ipdata.map((item) => (
          <div key={item.id} className="flex-item ">
              <Ipcard logo={item.logo}
                    name={item.name}
                    domain={item.domain}/>
           </div>
      ))}
    </div>
      <Footer showpage={false}/>
    </div>
  );
}
