import React, { useState, useRef } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Banner from "../Components/Banner";
import { IoIosArrowForward } from "react-icons/io";
import { RxCrossCircled } from "react-icons/rx";
import { professionalroles, internroles } from "../Data/Careers";
import { LuArrowRight } from "react-icons/lu";
import { professionalsapply, internsapply } from "../Data/Applydata";
import "./../App.css";
import emailjs from '@emailjs/browser';

export default function Careers() {
  const [isHovered, setIsHovered] = useState(false);
  const [isRolesOpen, setIsRolesOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [showProfessionalPopup, setShowProfessionalPopup] = useState(false);
  const [showInternPopup, setShowInternPopup] = useState(false);
  const [applyClick, setApplyClick] = useState(false);
  const [hoverStates, setHoverStates] = useState(
    Array(professionalroles.length).fill(false)
  );
  const [resume, setResume] = useState(null);
  const resumeFormRef = React.createRef();

  const handleResumeChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log(selectedFile.size)
    setResume(Math.floor(selectedFile.size/1024));
  };


  const form = useRef();
  const [uploadError, setUploadError] = useState(null)
  const sendEmail = (e) => {
    e.preventDefault();
    console.log(resume)
    if (resume<2040) {

      emailjs
        .sendForm('service_cui6i0a', 'template_p94hjas', form.current, {
          publicKey: 'rcWUEyKg3TYfCLt2-',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            form.current[0].value = ""
            form.current[1].value = ""
            form.current[2].value = ""
            form.current[3].value = ""
            setUploadError(null);
            window.scrollTo(0,0)
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );


    }
    else {
      setUploadError("Please check the file size")
    }
  };


  const closeAllModals = () => {
    setIsRolesOpen(false);
    setShowProfessionalPopup(false);
    setShowInternPopup(false);
  };

  const handleApplyClick = () => {
    closeAllModals();
    resumeFormRef.current.scrollIntoView({ behavior: "smooth" });

  };

  const handleRoleHover = (index, isHovered) => {
    const updatedHoverStates = [...hoverStates];
    updatedHoverStates[index] = isHovered;
    setHoverStates(updatedHoverStates);
  };

  const openModal = () => {
    setIsRolesOpen(true);
  };

  const closeModal = () => {
    setIsRolesOpen(false);
    setSelectedRole(null);
  };

  const handleInternClick = (role) => {
    setSelectedRole(role);
    setShowInternPopup(true);
  };

  const closeInternPopup = () => {
    setShowInternPopup(false);
    setApplyClick(false);
  };

  const handleProfessionalClick = (role) => {
    setSelectedRole(role);
    setShowProfessionalPopup(true);
  };

  const closeProfessionalPopup = () => {
    setShowProfessionalPopup(false);
    setApplyClick(false);
  };

  return (
    <div className="w-full bg-[#051525]">
      <Header id={7} />
      <Banner
        mainBanner={
          <div className="relative h-[95vh] flex items-center justify-center">
            <img
              className="w-full h-[95vh] object-cover brightness-75 "
              src={require("../../src/Assets/images/Careers .jpeg")}
            ></img>
            <div className="w-[90%] flex flex-col items-center justify-center transformBottom bottom-14 sm:bottom-5">
            <div
                className="hidden md:block font-opensans font-bold text-[6rem] text-left "
                style={{
                  WebkitTextStroke: "6px rgba(217,217,217,0.4) ",
                  color: "transparent",
                }}
              >
                Careers
              </div>
              <div
                className="block md:hidden font-opensans font-bold text-[3rem] text-left "
                style={{
                  WebkitTextStroke: "3px rgba(217,217,217,0.4) ",
                  color: "transparent",
                }}
              >
               Careers
              </div>
              <p className="font-opensans font-medium md:text-sm text-[0.6rem] text-white  text-center">
                Embark on a transformative journey with us! Explore exciting
                career opportunities that not only shape your professional path
                but also empower your personal growth. Join a dynamic community
                where innovation thrives, diversity is celebrated, and
                collaboration fuels success.
              </p>
            </div>
          </div>
        }
      />
      <div className=" flex-col items-center justify-center gap-6  px-19 pt-12">
        <p className="font-opensans font-semibold 2xl:text-3xl md:text-2xl text-white text-center">
          Thrive with Us: Exciting Careers Await!
        </p>
        <div className="w-full flex items-center justify-center gap-8 px-10">
          <p className="md:w-[49.75rem] w-[95%] md:py-6 py-2 text-justify font-opensans font-semibold 2xl:text-sm md:text-[0.7rem] text-[0.4rem] text-[#E6FCFF] ">
            We seek an unparalleled collaboration, uniting physicists,
            encompassing both experimentalists and theorists, alongside computer
            scientists, material scientists, and engineers. Visionary leaders
            from academia and industry are eager to synergize their expertise,
            fostering a dynamic partnership that unlocks the full potential of
            quantum computing.Join us in this transformative journey of quantum
            computing revolution.
          </p>
          <div
            onClick={openModal}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={`flex items-center justify-center md:gap-3 gap-1 bg-[#014F59] rounded-[0.6rem] md:px-3 md:py-1 px-2 hover:bg-[#0B294A]/30 hover:border-2 hover:border-[#FFFFFF]/25 cursor-pointer ${isHovered ? "custom-shadow-ip" : ""
              }`}
          >
            <p className="font-opensans font-semibold text-white md:text-sm text-[0.7rem]">
              Roles
            </p>
            <IoIosArrowForward size={13} color="#1B99D4" />
          </div>
          {isRolesOpen && (
            <div className="fixed top-0 left-0 w-full  mx-auto h-full flex items-center justify-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-10">
              <div className="w-[40rem] h-[31.25rem] flex flex-col items-center justify-start gap-8 py-6 px-11 rounded-[2rem] bg-[#0C2E52]">
                <div className="w-full flex items-center justify-end mt-4 cursor-pointer">
                  <RxCrossCircled
                    size={30}
                    color="#03B6CC"
                    onClick={closeModal}
                  />
                </div>
                <div className="w-full overflow-x-auto scrollbar-hide ">
                  <div className="w-full  flex flex-col items-center justify-center gap-2 mb-6">
                    <p className="font-opensans font-extrabold text-2xl text-[#FEFEFE]">
                      For Professionals
                    </p>

                    <div class="flex flex-wrap">
                      {professionalroles.map((role, index) => (
                        <div
                          key={role.id}
                          class="w-full md:w-1/2 lg:w-1/3 xl:w-1/2 p-4"
                        >
                          <div class="bg-white/25 shadow-md p-4 rounded-xl">
                            <p class="font-opensans font-bold text-[0.675rem] text-[#BDDAFA] h-[2rem]">
                              {role.name}
                            </p>
                            <div className="w-full flex items-center justify-end">
                              <div
                                onMouseOver={() => handleRoleHover(index, true)}
                                onMouseLeave={() =>
                                  handleRoleHover(index, false)
                                }
                                className={`flex items-center justify-center gap-1 hover:bg-[#0E3054] rounded-2xl px-2 py-1 cursor-pointer ${hoverStates[index] ? "bg-[#0E3054]" : ""
                                  }`}
                              >
                                <div className="flex  items-center justify-center gap-1 hover:bg-[#0E3054] rounded-lg px-1 cursor-pointer">
                                  <div className=" flex items-center justify-center w-[1.5rem] h-[1.1rem] bg-[#0E3054] rounded-full">
                                    <LuArrowRight color="white" size={10} />
                                  </div>
                                  <p
                                    onClick={() => {
                                      handleProfessionalClick(role.name);
                                    }}
                                    className="font-opensans font-semibold text-[0.5rem] text-white text-left"
                                  >
                                    Apply
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="w-full  flex flex-col items-center justify-center gap-2 ">
                    <p className="font-opensans font-extrabold text-2xl text-[#FEFEFE]">
                      For Interns
                    </p>

                    <div class="flex flex-wrap">
                      {internroles.map((role, index) => (
                        <div
                          key={role.id}
                          class="w-full md:w-1/2 lg:w-1/3 xl:w-1/2 p-4"
                        >
                          <div class="bg-white/25 shadow-md p-4 rounded-lg ">
                            <p class="font-opensans font-bold text-[0.675rem] text-[#BDDAFA] h-[2rem]">
                              {role.name}
                            </p>
                            <div className="w-full flex items-center justify-end">
                              <div
                                onMouseOver={() => handleRoleHover(index, true)}
                                onMouseLeave={() =>
                                  handleRoleHover(index, false)
                                }
                                className={`flex items-center justify-center gap-1 hover:bg-[#0E3054] rounded-2xl px-2 py-1 cursor-pointer ${hoverStates[index] ? "bg-[#0E3054]" : ""
                                  }`}
                              >
                                <div className="flex  items-center justify-center gap-1 hover:bg-[#0E3054] rounded-lg px-1 cursor-pointer">
                                  <div className=" flex items-center justify-center w-[1.5rem] h-[1.1rem] bg-[#0E3054] rounded-full">
                                    <LuArrowRight color="white" size={10} />
                                  </div>
                                  <p
                                    onClick={() => {
                                      handleInternClick(role.name);
                                    }}
                                    className="font-opensans font-semibold text-[0.5rem] text-white text-left"
                                  >
                                    Apply
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {showProfessionalPopup && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-10">
              <div className="w-[50rem] h-[40rem] flex flex-col items-center justify-start gap-8 py-6 px-11 rounded-[2rem] bg-[#0C2E52]">
                <div className="w-full flex items-center justify-end mt-4 cursor-pointer">
                  <RxCrossCircled
                    size={30}
                    color="#03B6CC"
                    onClick={closeProfessionalPopup}
                  />
                </div>
                {/* <div className="w-full flex flex-col items-center justify-center gap-2 mb-6"> */}
                <p className="font-opensans font-extrabold text-2xl text-[#FEFEFE]">
                  {
                    professionalsapply.find((job) => job.name === selectedRole)
                      .name
                  }
                </p>
                <div className="w-full overflow-x-auto scrollbar-hide mb-2">
                  {Object.entries(
                    professionalsapply.find((job) => job.name === selectedRole)
                  ).map(([key, value]) => {
                    if (value !== "" && key !== "id" && key !== "name") {
                      return (
                        <div key={key}>
                          {typeof value === "object" ? (
                            <div>
                              {Object.entries(value).map(
                                ([nestedKey, nestedValue]) => (
                                  <p className="py-1" key={nestedKey}>
                                    <span className="font-bold font-opensans text-[#FEFEFE] text-[1rem] mr-2 leading-tight">
                                      {nestedKey} :
                                    </span>
                                    {"   "}
                                    {Array.isArray(nestedValue) ? (
                                      <ul className="list-disc pl-4 my-1">
                                        {nestedValue.map((item, index) => (
                                          <li
                                            key={index}
                                            className="font-bold font-opensans text-[#FEFEFE]/60 text-[0.675rem] leading-tight"
                                          >
                                            {item}
                                          </li>
                                        ))}
                                      </ul>
                                    ) : (
                                      <span className="font-bold font-opensans text-[#FEFEFE]/60 text-[0.675rem] leading-tight">
                                        {nestedValue}
                                      </span>
                                    )}
                                  </p>
                                )
                              )}
                            </div>
                          ) : (
                            <p>{value}</p>
                          )}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
                <button
                  onClick={handleApplyClick}
                  className="bg-[#03B6CC] text-white px-4 py-2 rounded-md hover:bg-[#02A3B3]/60 mt-auto"
                >
                  Apply
                </button>
              </div>
            </div>
          )}
          {showInternPopup && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-10">
              <div className="w-[50rem] h-[40rem] flex flex-col items-center justify-start gap-8 py-6 px-11 rounded-[2rem] bg-[#0C2E52]">
                <div className="w-full flex items-center justify-end mt-4 cursor-pointer">
                  <RxCrossCircled
                    size={30}
                    color="#03B6CC"
                    onClick={closeInternPopup}
                  />
                </div>
                <div className="w-full flex flex-col items-center justify-center gap-2 mb-6">
                  <p className="font-opensans font-extrabold text-2xl text-[#FEFEFE]">
                    {
                      internsapply.find((job) => job.name === selectedRole)
                        .name
                    }
                  </p>
                  <div className="w-full overflow-x-auto scrollbar-hide mb-2">
                    {Object.entries(
                      internsapply.find((job) => job.name === selectedRole)
                    ).map(([key, value]) => {
                      if (value !== "" && key !== "id" && key !== "name") {
                        return (
                          <div key={key}>
                            {typeof value === "object" ? (
                              <div>
                                {Object.entries(value).map(
                                  ([nestedKey, nestedValue]) => (
                                    <p className="py-1" key={nestedKey}>
                                      <span className="font-bold font-opensans text-[#FEFEFE] text-[1rem] mr-2">
                                        {nestedKey} :
                                      </span>
                                      {"   "}
                                      {Array.isArray(nestedValue) ? (
                                        <ul className="list-disc pl-4 my-1">
                                          {nestedValue.map((item, index) => (
                                            <li
                                              key={index}
                                              className="font-bold font-opensans text-[#FEFEFE]/60 text-[0.675rem]"
                                            >
                                              {item}
                                            </li>
                                          ))}
                                        </ul>
                                      ) : (
                                        <span className="font-bold font-opensans text-[#FEFEFE]/60 text-[0.675rem]">
                                          {nestedValue}
                                        </span>
                                      )}
                                    </p>
                                  )
                                )}
                              </div>
                            ) : (
                              <p>{value}</p>
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  <button
                    onClick={handleApplyClick}
                    className="bg-[#03B6CC] text-white px-4 py-2 rounded-md hover:bg-[#02A3B3]/60 mt-auto"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          )}


        </div>
      </div>
      <div
        ref={resumeFormRef}
        className="md:w-[40%] mx-auto items-center justify-center px-10 py-10"
      >
        <div className=" px-10 py-6 bg-white/10  contact-shadow">
          <h2 className="md:text-[2.1rem] text-[1.5rem] font-opensans font-extrabold mb-6 text-[#D9FBFF]/50">
            Send your Resume
          </h2>
          <form ref={form} onSubmit={sendEmail} >
            <div className="md:mb-4 mb-1">
              <label
                htmlFor="name"
                className="block md:text-sm text-[0.6rem] font-extrabold text-white font-opensans"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="user_name"


                className="mt-1 p-2 w-full border-b text-white border-[#D9FBFF]/50 rounded-md bg-white/10 cursor-pointer"
                required
              />
            </div>

            <div className="md:mb-4 mb-1">
              <label
                htmlFor="phoneNumber"
                className="block md:text-sm text-[0.6rem] font-extrabold text-white font-opensans"
              >
                Phone Number
              </label>
              <input
                type="tel"
                id="phoneNumber"
                name="user_phoneNumber"

                className="mt-1 p-2 w-full border-b text-white border-[#D9FBFF]/50 rounded-md bg-white/10 cursor-pointer"
                required
              />
            </div>

            <div className="md:mb-4 mb-1">
              <label
                htmlFor="email"
                className="block md:text-sm text-[0.6rem] font-extrabold text-white font-opensans"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="user_email"

                className="mt-1 p-2 w-full border-b text-white border-[#D9FBFF]/50 rounded-md bg-white/10 cursor-pointer"
                required
              />
            </div>
            <div className="md:mb-4 mb-1">
              <label
                htmlFor="subject"
                className="block md:text-sm text-[0.6rem] font-extrabold text-white font-opensans"
              >
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="user_message"
                value={selectedRole}

                className="mt-1 p-2 w-full border-b text-white border-[#D9FBFF]/50 rounded-md bg-white/10 cursor-pointer"
              />
            </div>

            <div className="md:mb-4 mb-1">
              <label
                htmlFor="resume"
                className="block md:text-sm text-[0.6rem] font-extrabold text-white font-opensans"
              >
                Upload Resume
              </label>
              <input onChange={handleResumeChange} type="file" name="file_upload" className="mt-2 text-white bg-transparent md:text-sm text-[0.6rem]" />
              <p className="text-white md:text-xs text-[0.4rem] mt-1">File Size should {"<"}2 mb</p>
            </div>
                    {uploadError?
                    <p className=" text-red-600 ">{uploadError}</p>
                  :null}
            <button
              type="submit"
              className="bg-[#D9FBFF]/60 text-white text-[0.6rem] md:text-sm md:px-4 md:py-2 px-2 py-1 rounded-md hover:bg-[#D9FBFF]/30"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <Footer showpage={false} />
    </div>
  );
}
