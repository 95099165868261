import React, { useState } from "react";
import { LuArrowRight } from "react-icons/lu";

export default function Publicationcard(props) {
  const [mainblock, setMainBlock] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [Read, setRead] = useState(false);
  const handleReadMoreClick = (url,event) => {
    setRead(true);
    // Send data to Logoscontainer
    props.onReadMoreClick({ id: props.id });
    event.stopPropagation();
        if (url) {
          window.open(url, '_blank');
        }  // Assuming you have a 'name' prop in Logocard
  };
  return (
    <div
      onMouseOver={() => setMainBlock(true)}
      onMouseLeave={() => setMainBlock(false)}
      className="md:w-[60rem]  w-full h-auto bg-[#D9FBFF]/50 hover:bg-[#D9FBFF]/20 flex flex-col items-center justify-center gap-2 md:rounded-[2rem] rounded-[1rem] px-2 py-1"
    >
      <div className="w-full flex items-center justify-between">
      <div className="w-full h-full flex flex-col items-start justify-between gap-3 md:px-10 md:py-3 px-5 py-2">
        <div className=" flex flex-col items-start justify-center gap-2 ">
        <p
           onClick={(event) => handleReadMoreClick(props.link, event)}
          className={`text-black font-opensans font-extrabold md:text-lg text-md md:text-left text-center ${
            mainblock ? "text-white" : ""
          }`}
        >
          {props.name}
        </p>
        {/* <p
          className={`text-white/60 font-opensans font-bold md:text-[0.6rem] text-[0.45rem] text-justify md:h-[4rem]   ${
            mainblock ? "text-white" : ""
          }`}
        >
          {props.content}
        </p> */}
        </div>
        {/* <div className="w-full flex items-center justify-start">
        <div
          onMouseOver={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className={`flex items-center justify-center gap-1  rounded-2xl px-2 py-1 cursor-pointer ${
            isHovered ? "bg-[#051525]" : ""
          }`}
        >
          <div
            onMouseOver={() => setRead(true)}
            onMouseLeave={() => setRead(false)}
            onClick={(event) => handleReadMoreClick(props.link, event)}
            className="flex  items-center justify-center gap-1  rounded-lg  cursor-pointer"
          >
            <div className="bg-[#051525] flex items-center justify-center md:w-[2.2rem] md:h-[2.1rem] w-[1.5rem] h-[1.4rem]  rounded-full">
            <LuArrowRight color="#DBE0E5" size={20} className='md:block hidden'/>
                  <LuArrowRight color="#DBE0E5" size={12} className='block md:hidden'/>
            </div>
            <p className="font-opensans font-bold md:text-[0.8rem] text-[0.4rem] text-left text-white">
              Read More...
            </p>
          </div>
        </div> */}
      </div>
      </div>
      </div>

    
  );
}
