import React from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Banner from "../Components/Banner";
import Labcontainerr from '../Components/Labcontainer';
import "./../App.css";

export default function Labs() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div className="w-full bg-[#051525]">
    <Header id={5}/>
    <Banner
      mainBanner={
        <div className="relative h-[95vh] flex items-center justify-center">
          <img
            className="w-full h-[95vh] object-cover mix-blend-exclusion brightness-100  saturate-50"
            src={require("../../src/Assets/images/Lab.jpeg")}
          ></img>
          <div className="flex flex-col items-start justify-center transformleft sm:left-14 left-4  gap-4">
            <div
              className=" font-opensans font-bold text-6xl text-left hidden md:block "
              style={{ WebkitTextStroke: "2px white ", color: "transparent" }}
            >
              Labs
            </div>
            <div
                className=" font-opensans font-bold text-3xl text-left block md:hidden"
                style={{ WebkitTextStroke: "2px white ", color: "transparent" }}
              >
                Labs
              </div>
            <p className="font-opensans font-medium md:text-sm text-[0.6rem] text-white sm:w-[25.68rem] w-[20rem] text-left">
            "Unleashing Quantum Potential: Pioneering Tomorrow's Technology Today." Quantum labs are at the forefront of scientific exploration, unraveling the mysteries of quantum mechanics to revolutionize computing, communication, and sensing. These labs strive to harness the extraordinary power of quantum phenomena, shaping a future where quantum technologies redefine the boundaries of what's possible.
            </p>
          </div>
        </div>
      }
    />
    <p className="w-full text-center font-opensans font-extrabold 2xl:text-3xl md:text-2xl text-[#E7EAEE] mt-28">
          Lab Showcase
        </p>
      <div className="w-full h-auto relative flex items-center justify-center px-5 mb-10 md:gap-8 gap-4">
           <Labcontainerr/>  
      </div>
    <div className="flex flex-col items-center justify-center px-20 py-10">
        <p className="font-opensans font-extrabold 2xl:text-3xl md:text-2xl text-[#E7EAEE] mb-6">
          Lab Gallery
        </p>
        <div className='flex md:flex-row flex-col gap-4'>
          <div className='flex flex-col gap-3'>
               <img className='md:h-[14rem] md:w-[31.25rem] w-full h-[9rem] rounded-sm' src={require("../../src/Assets/images/lab10.jpg")}></img>
               <img className='md:h-[14rem] md:w-[31.25rem] w-full h-[9rem] rounded-sm' src={require("../../src/Assets/images/image 2.png")}></img>
          </div>
         
          <div className='flex flex-col gap-3'>
          <img className="md:w-[35rem] h-[9rem] w-full rounded-sm object-cover" src={require("../../src/Assets/images/lab11.jpg")}></img>
               <img className="md:w-[35rem] h-[9rem] w-full rounded-sm object-cover" src={require("../../src/Assets/images/lab12.jpg")}></img>
               <img className="md:w-[35rem] h-[9rem] w-full rounded-sm object-cover" src={require("../../src/Assets/images/lab13.jpg")}></img>
          </div>
         

        </div>
    </div>
    <Footer showpage={false}/>
    </div>
  )
}
