export const profiledata = [
  {
    id: 1,
    name: "Mr. Sanjay Chittore",
    img: require("../../src/Assets/images/SANJAY.png"),
    linkedin: "https://www.linkedin.com/in/sanjaychittore/",
    title: "CEO, Qulabs",
  },
  {
    id: 2,
    name: "Mr. Kapil Jaiswal",
    img: require("../../src/Assets/images/KAPIL.png"),
    linkedin: "",
    title:"CTO",
    
  },

 
  {
    id: 3,
    name: "Gopika Changanti",
    img: require("../../src/Assets/images/Gopika.png"),
    linkedin: "https://www.linkedin.com/in/gopika-chaganti/",
    title: "COO",
  },
  {
    id: 4,
    name: "Dr. Sachin Barthwal",
    img: require("../../src/Assets/images/SACHIN.png"),
    linkedin: "https://www.linkedin.com/in/sachin-barthwal-38190322a/",
    title: "Quantum Product Head",
  },
 
  {
    id: 5,
    name: "Mr. Devendra Mishra",
    img: require("../../src/Assets/images/DEVENDER.png"),
    linkedin: "https://www.linkedin.com/in/devendra-mishra444/",
    title: "Head of Quantum Hardware",
   
  },
  {
    id: 6,
    name: "Mr. Rikteem Bhowmick",
    img: require("../../src/Assets/images/RIKTHIM.png"),
    linkedin: "https://www.linkedin.com/in/rikteem-bhowmick-aa3050228/",
    title: "Head of Quantum Communication",
   
  },
  {
    id: 7,
    name: "Dr. Bappaditya Sankhari",
    img: require("../../src/Assets/images/BAPPA.png"),
    linkedin: "https://www.linkedin.com/in/bappaditya-sankhari-6b5268a8/",
    title: "Head of Quantum Architecture",
  },
  {
    id: 8,
    name: "Mr. Rama Theja",
    img: require("../../src/Assets/images/RAM THEJA.png"),
    linkedin: "https://www.linkedin.com/in/ramatheja/",
    title: "Head Opto Electronics",
  },
  {
    id: 9,
    name: "Dr. Aishik Acharya",
    img: require("../../src/Assets/images/ACHARYA.png"),
    linkedin: "https://www.linkedin.com/in/dr-aishik-acharya-7a717287/",
    title: "Head of Quantum Engineering ",
  },
  // {
  //   id:8,
  //   name:"Dr. Avinash Chalumuri",
  //   img:require("../../src/Assets/images/AVINASH.png"),
  //   linkedin:"https://www.linkedin.com/in/avinash-chalumuri-phd-57ab2616b/",
  //   title:"QML Scientist"
  // }

];

export const advisor = [
  {
    id: 1,
    name: "Nixon Patel",
    linkedin: "https://www.linkedin.com/in/nixonpatel/",
    title: "Technology Advisor",
  },
  {
    id: 2,
    name: "Prof. Sugata Gangopadhyay",
    linkedin: "https://www.linkedin.com/in/sugata-gangopadhyay-08354219/",
    title: "Head of the Department, CSE, IIT Roorkee",
   
  },
  {
    id: 3,
    name: "Prof.M.V.Panduranga Rao",
    linkedin: "",
    title: "Associate professor, CSE, IIT Hyderabad",
    
  },
  {
    id: 4,
    name: "Prof. Arijit Sharma",
    linkedin: "https://www.linkedin.com/in/arijit-sharma-4261a923/",
    title: "Assistant Professor. IIT Tirupati",
   
  },
  {
    id: 5,
    name: "Mr. Naresh Kumar Emani",
    linkedin: "https://www.linkedin.com/in/naresh-kumar-emani-a9b9377/",
    title: "Position: Associate Professor",
   
  },
  {
    id: 6,
    name: "Mr. Sai Santosh Kumar Raavi",
    linkedin: "https://www.linkedin.com/in/sai-santosh-kumar-raavi-28715882/",
    title: "Position: Associate Professor",
   
  },
  {
    id: 7,
    name: "Prof.Ajay Wasan",
    linkedin: "https://www.linkedin.com/in/ajay-wasan-15116aa/",
    title: "Position: Associate Professor",
  },
  {
    id: 8,
    name: "Prof. Debabrata Goswami",
    linkedin: "https://www.linkedin.com/in/debabrata-goswami-a453014/",
    title: "Professor",
  },
  {
    id: 10,
    name: "Dr. Mansi Dhuria",
    linkedin: "https://www.linkedin.com/in/dr-mansi-dhuria-633a79125/",
    title: "Assistant Professor at PDEU Gandhinagar",
  },
  
  
];
